<template>
  <v-container
    fluid
    class="program-settings"
  >
    <v-row justify="space-between">
      <v-col
        cols="auto"
        class="title-m-bold neutral-900--text"
      >
        Корзина
      </v-col>

      <v-col cols="auto">
        <v-btn
          color="error"
          :disabled="deleteAllButtonDisableStatus || trashListAction"
          @click="deleteAllItems"
        >
          <v-icon left>
            $iconify_feather-trash
          </v-icon>
          Удалить все
        </v-btn>
        <v-btn
          class="ml-2"
          color="success"
          :disabled="restoreAllButtonDisableStatus || trashListAction"
          @click="restoreAllItems"
        >
          <v-icon left>
            $icons_trash-arrow
          </v-icon>
          Восстановить все
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-text-field
        placeholder="Быстрый поиск"
        single-line
        solo
        v-model.trim="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        class="custom-input"
        @input="getTrashListAction(1000)"
        clearable
      />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-list dense>
          <v-list-item-group
            v-model="selectedItemList"
            color="primary"
          >
            <v-list-item
              v-for="(item, i) in itemsList"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.text" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col cols="9">
        <v-row
          align="baseline"
          justify="space-around"
        >
          <v-col cols="2">
            <p v-if="selectedItemsTable.length > 0">
              Выбрано {{ selectedItemsTable.length }}
            </p>
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="10"
          >
            <v-btn
              class="ma-2"
              depressed
              :loading="cancelItemsButtonsStatuses.loading"
              :disabled="cancelItemsButtonsStatuses.disable || trashListAction"
              color="primary"
              @click="cancelSelectItems"
            >
              Отменить
            </v-btn>

            <v-btn
              class="ma-2"
              depressed
              :disabled="deleteItemsButtonStatuses.disable || trashListAction"
              :loading="deleteItemsButtonStatuses.loading"
              color="error"
              @click="deleteSelectItems"
            >
              Удалить
            </v-btn>

            <v-btn
              class="ma-2"
              depressed
              :loading="restoreItemsButtonsStatuses.loading"
              :disabled="restoreItemsButtonsStatuses.disable || trashListAction"
              color="success"
              @click="restoreSelectItems"
            >
              Восстановить
            </v-btn>
          </v-col>
        </v-row>

        <v-data-table
          v-model="selectedItemsTable"
          show-select
          :headers="headers"
          :items="trashList"
          :single-select="false"
          item-key="id"
          class="trash-table"
          :items-per-page="pagination.itemsPerPage"
          :server-items-length="totalCount"
          :disable-sort="true"
          loading-text="Записи загружаются..."
          :loading="loadingDataTable"
          @pagination="updatePagination"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="restoreItem(item)"
            >
              mdi-restore
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { CancelToken } from 'axios'

  export default {
    components: {},
    data () {
      return {
        cancelTokenSource: CancelToken.source(),
        trashListAction: false,
        trashListActionId: null,
        search: "",
        deleteAllButtonDisableStatus: true,
        restoreAllButtonDisableStatus: true,
        loadingDataTable: true,
        deleteItemsButtonStatuses: {
          loading: false,
          disable: false,
        },
        restoreItemsButtonsStatuses: {
          loading: false,
          disable: false,
        },
        cancelItemsButtonsStatuses: {
          loading: false,
          disable: false,
        },
        selectedItemsTable: [],
        selectedItemList: 0,
        itemsList: [{ text: 'Аккаунты' }],
        headers: [
          { text: 'ID', value: 'id' },
          { text: 'Имя клиента', value: 'client_name' },
          { text: 'Баланс', value: 'main_balance' },
          { text: 'Телефон', value: 'phone' },
          { text: 'Время удаления', value: 'deleted_at_display' },
          { text: 'Действие', value: 'actions', sortable: false },
        ],
        pagination: {
          page: 1,
          itemsPerPage: 10,
        },
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        trashList: 'company/trash/trashList',
        totalCount: 'company/trash/totalCount',
      }),

      formatingTrashItemsToIds: function () {
        return this.selectedItemsTable.map(function (item) {
          return item.id
        })
      },
    },
    watch: {
      selectedItemsTable: function (val) {
        if (val.length > 0) {
          this.enableButtons()
        } else {
          this.disableButtons()
        }
      },
      programId: async function (val) {
        this.loadingDataTable = true
        await this.fetch()
        this.pagination.page = 1
        this.pagination.itemsPerPage = 10
      },
      trashList: function (val) {
        if (val.length > 0) {
          this.deleteAllButtonDisableStatus = false
          this.restoreAllButtonDisableStatus = false
        } else {
          this.deleteAllButtonDisableStatus = true
          this.restoreAllButtonDisableStatus = true
        }
      },
    },

    mounted () {
      this.disableButtons()
      this.fetch()
    },

    methods: {
      ...mapActions({
        getTrashList: 'company/trash/getTrashList',
        deleteAll: 'company/trash/deleteAll',
        restoreAll: 'company/trash/restoreAll',
        restore: 'company/trash/restore',
        delete: 'company/trash/delete',
      }),

      disableButtons () {
        this.deleteItemsButtonStatuses.disable = true
        this.restoreItemsButtonsStatuses.disable = true
        this.cancelItemsButtonsStatuses.disable = true
      },

      enableButtons () {
        this.deleteItemsButtonStatuses.disable = false
        this.restoreItemsButtonsStatuses.disable = false
        this.cancelItemsButtonsStatuses.disable = false
      },

      async updatePagination (page) {
        if (
          this.pagination.itemsPerPage !== page.itemsPerPage ||
          this.pagination.page !== page.page
        ) {
          this.pagination.itemsPerPage = page.itemsPerPage
          this.pagination.page = page.page
          this.loadingDataTable = true

          await this.getTrashListAction()

          this.loadingDataTable = false
        }
      },

      async fetch () {
        await this.getTrashListAction()
        this.loadingDataTable = false
      },

      async deleteSelectItems () {
        if (this.formatingTrashItemsToIds.length !== 0) {
          this.disableButtons()
          this.loadingDataTable = true
          try {
            await this.delete({
              programId: this.programId,
              ids: this.formatingTrashItemsToIds,
            })
            this.selectedItemsTable = []
            await this.getTrashListAction()
          } finally {
            this.loadingDataTable = false
            this.enableButtons()
          }
        }
      },

      async restoreSelectItems () {
        if (this.formatingTrashItemsToIds.length !== 0) {
          this.disableButtons()
          this.loadingDataTable = true
          try {
            await this.restore({
              programId: this.programId,
              ids: this.formatingTrashItemsToIds,
            })
            this.selectedItemsTable = []
            await this.getTrashListAction()
          } finally {
            this.loadingDataTable = false
            this.enableButtons()
          }
        }
      },

      async deleteItem (item) {
        this.loadingDataTable = true
        await this.delete({
          programId: this.programId,
          ids: [item.id],
        })
        await this.getTrashListAction()
        this.loadingDataTable = false
      },

      async restoreItem (item) {
        this.loadingDataTable = true
        await this.restore({
          programId: this.programId,
          ids: [item.id],
        })
        await this.getTrashListAction()
        this.loadingDataTable = false
      },

      cancelSelectItems () {
        this.selectedItemsTable = []
      },

      async deleteAllItems () {
        try {
          await this.$alert(
            'Вы действительно хотите удалить эти ' + this.totalCount + ' записей безвозвратно?',
            this.$t('Полное удаление всех клиентов'),
            {
              confirmButtonText: 'Ок',
              showCancelButton: true,
              cancelButtonText: 'Отмена',
              type: 'warning',
            },
          )

          this.loadingDataTable = true
          await this.deleteAll(this.programId)
          this.$notify({
            type: 'success',
            title: 'Корзина',
            text: 'Запрос на удаление принят. Записи вскоре будут удалены.',
          })
        } finally {
          this.loadingDataTable = false
        }
      },

      async restoreAllItems () {
        try {
          await this.$alert(
            'Вы действительно хотите востановить эти ' + this.totalCount + ' записей?',
            this.$t('Полное востановление всех клиентов'),
            {
              confirmButtonText: 'Ок',
              showCancelButton: true,
              cancelButtonText: 'Отмена',
              type: 'warning',
            },
          )

          this.loadingDataTable = true
          await this.restoreAll(this.programId)
        } finally {
          this.loadingDataTable = false
        }
      },

      async getTrashListAction (delay = 0) {

        if (this.trashListActionId) {
          clearTimeout(this.trashListActionId)
        }

        if (delay > 0) {
          this.trashListActionId = setTimeout(() => {
            this.getTrashListAction(0)
          }, delay)
          return
        }

        let search = null
        if (this.search.length > 3) search = this.search

        if (this.trashListAction) {
          this.cancelTokenSource.cancel('Operation canceled')
        }

        try {
          this.trashListAction = true
          this.cancelTokenSource = CancelToken.source()
          await this.getTrashList({
            programId: this.programId,
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page - 1) * this.pagination.itemsPerPage,
            search,
            cancelToken: this.cancelTokenSource.token
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.trashListAction = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped></style>
